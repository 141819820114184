<script>
import partnerChoices from '@/mixins/partnerChoices';
import conditionalGroupReset from '@/mixins/conditionalGroupReset';
import { BIconInfoCircle,BTooltip } from 'bootstrap-vue';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import agreementValidation from '@/mixins/agreementValidation'
import { mapState } from 'vuex';

export default {
	mixins: [partnerChoices, conditionalGroupReset,validationMixin,agreementValidation],
	components:{BIconInfoCircle,BTooltip},
	props: {
		agreementData: Object,
		label: String,
		noneOfTheAbove: Boolean,
		options: {
			type: Array,
			default() {
				return [];
			},
		},
		other: Boolean,
		partner: Boolean,
		select: Boolean,
		value: [String, Number, Boolean, Object],
		inputCols: String,
		testid:String,
		name: String,
		length: String,
		disabled:Boolean,
		toolTipText:String,
		validation: Boolean
	},
	data() {
		return {
			localValue: null,
			otherValue: null,
		};
	},
	computed: {
		...mapState(['currentRoleLevel']),
		otherSelected() {
			return this.localValue === 'Other';
		},
		currentValue() {
			return this.otherSelected ? this.otherValue : this.localValue;
		},
		displayOtherValueInput() {
			return this.other && this.otherSelected;
		},
		normalizedOptions() {
			return this.options.map((item) => {
				if (item.text) {
					return item;
				} else {
					return { text: item, value: item };
				}
			});
		},
		localOptions() {
			return [
				...(this.partner ? this.partnerChoices : []),
				...this.normalizedOptions,
				...(this.other ? ['Other'] : []),
				...(this.noneOfTheAbove
					? [{ text: 'None of the Above', value: false }]
					: []),
			];
		},
		isUserSeparatingParty(){
			return this.currentRoleLevel.roleAssociated === 'party'
		}
	},
	watch: {
		currentValue() {
			this.$emit('input', this.currentValue);
		},
		value() {
			this.updateValue();
		},
	},
	mounted() {
		this.updateValue();
	},
	methods: {
		updateValue() {
			const value = this.value;

			if (this.other === false) {
				this.localValue = value;
				return;
			}

			const selectedOption = this.localOptions.find(
				(option) => option.value === value || option === value
			);

			if (selectedOption === undefined && value !== null) {
				this.localValue = 'Other';
				this.otherValue = value;
			} else if (value === null && this.localValue === 'Other') {
				this.localValue = 'Other';
				this.otherValue = null;
			} else {
				this.localValue = value;
				if (this.localValue !== 'Other') {
					this.otherValue = null;
				}
			}
		},
		validateState() {
			const { $dirty, $error } = this.$v.localValue;
			return $dirty ? !$error : null;
		},
		triggerValidation(){
			this.$v.$touch()
		}
	},
	validations:{
		localValue:{
			required
		}
	}
};
</script>

<template>
	<b-form-group :label="label" :class="validation? 'field-required-group' : ''">
		<b-row no-gutters>
			<b-col :class="(length === 'long' ? 'col-12' : 'col-6') + ' col-sm-' + inputCols">
				<div v-if="select">
					<b-form-select
						:id="name"
						v-model="$v.localValue.$model"
						:options="localOptions"
						:name="name"
						:data-testid="testid"
						:disabled="disabled"
						:state="validation? validateState(): null"
						:class="{ 'custom-disabled': disabled }"
					/>
					<div
						v-if="$v.localValue.$error && validation"
						class="invalid-feedback d-block col-12"
					>
						This is a required field.
					</div>
				</div>
				
				<!-- <b-form-radio-group
					v-else
					:id="name"
					v-model="localValue"
					stacked
					:options="localOptions"
					:name="name"
					:data-testid="testid"
					:disabled="disabled"
				/> -->
				<b-form-radio-group v-else :id="name" stacked :data-testid="testid">
					<b-form-radio
						v-for="(option, index) in localOptions"
						:key="index"
						:value="option.value"
						:name="name"
						:disabled="disabled"
						v-model="localValue"
						:data-testid="testid+index"
					>
						{{ option.text }}
					</b-form-radio>
				</b-form-radio-group>
				<b-form-input
					v-if="displayOtherValueInput"
					:id="name"
					v-model="otherValue"
					class="mt-2"
					:name="name"
					:data-testid="testid"
					:disabled="disabled"
				/>
			</b-col>
			<b-col class="info-outer" v-if="toolTipText">
				<!-- <b-icon-info-circle id="tooltip-target-radiogroup"></b-icon-info-circle>
				<b-tooltip target="tooltip-target-radiogroup">
					{{toolTipText}}
				</b-tooltip> -->
				<b-icon-info-circle class="ml-2"
					v-if="toolTipText && isUserSeparatingParty"
					v-b-tooltip.html="{ title: toolTipText, boundary: 'viewport' }"
					>
				</b-icon-info-circle>
			</b-col>
		</b-row>
	</b-form-group>
</template>
<style scoped>
.info-outer {
	align-self: center;
    margin-left: 0.5rem;
}
</style>