<script>
import Vue from 'vue';
import Navigation from './components/Navigation';
import { mapState } from 'vuex';
import Fragment from 'vue-fragment';
import Intercom from './Intercom.vue'; 
import userflow from 'userflow.js'
import { auth } from './firebase';
import UAParser from 'ua-parser-js';

Vue.use(Fragment.Plugin);
export default {
	components: { Navigation, Intercom },
	data() {
		return {
			userflowInitialized: false
		}
	},
	computed: {
		...mapState(['user','currentRoleLevel','currentAgreementRoleLevelName','customerDetails'])
	},
	methods: {
		initializeChatBot(){
			this.$root.$emit('appendIntercomScript');
		},
		setInitialDataOnLoad(){
			this.$store.dispatch('getAllRoleLevels')
		},
        handleLogoutEvent(event) {
            if (event.key === 'logout') {
                this.$router.push({ path: '/auth' });
                location.reload();
			}
		},
		updateUserflow() {
			const user = this.user;
			if (user && user.uid) {
				if (!this.userflowInitialized) {
					userflow.init(process.env.VUE_APP_USERFLOW_TOKEN);
					this.userflowInitialized = true;
				}

				try {
					const deviceDetails = new UAParser();

					const identifyPayload = {
					UID: user.uid,
					roleLevel: this.currentRoleLevel?.roleName,
					roleLevelPerAgreement: this.currentAgreementRoleLevelName,
					isSharedUser: this.customerDetails?.isSharedUser || false,
					signed_up_at: auth.currentUser.metadata?.creationTime 
						? new Date(auth.currentUser.metadata.creationTime).toISOString() 
						: undefined,
					...(this.customerDetails?.email && { email: this.customerDetails.email }),
					...(this.customerDetails?.displayName && { displayName: this.customerDetails.displayName }),
					...(this.customerDetails?.displayName || this.customerDetails?.lastName 
						? { name: `${this.customerDetails.displayName || ""} ${this.customerDetails.lastName || ""}`.trim() } 
						: {}),
					browser_name: deviceDetails?.getBrowser().name,
					os_name: deviceDetails?.getOS().name,
					};

					userflow.identify(user.uid, identifyPayload);
				} catch (error) {
					console.error("Error identifying user in Userflow:", error);
				}
			}
		}
	},
	mounted(){
		this.initializeChatBot()
		this.setInitialDataOnLoad();
		window.addEventListener('storage', this.handleLogoutEvent);
	},
    beforeDestroy() {
        window.removeEventListener('storage', this.handleLogoutEvent);
    },
	watch: {
		user: {
			handler(newUser) {
				this.updateUserflow();
			},
			immediate: true
		},
		currentRoleLevel: {
			handler() {
				this.updateUserflow();
			},
			immediate: true
		},
		currentAgreementRoleLevelName: {
			handler() {
				this.updateUserflow();
			}
		},
		customerDetails: {
            handler() {
                this.updateUserflow();
            }
        }
	},
};
</script>

<template>
	<div id="app">
		<intercom/>
		<navigation :user="user" />
		<router-view />
	</div>
</template>

<style>
#app .form-group {
	margin-bottom: unset;
}

.app-input-group .input {
	margin: 0.1em;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.intercom-lightweight-app-launcher{
	bottom: 4.5rem !important;
}
</style>
