<script>
import allProvinces from 'provinces';
import filter from 'lodash/filter';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import agreementValidation from '@/mixins/agreementValidation'
import { mapState } from 'vuex';

const provinces = filter(allProvinces, { country: 'CA' });

export default {
	mixins:[validationMixin,agreementValidation],
	props: {
		value: {
			type: Object,
			default() {
				return {
					street: '',
					province: '',
					city: '',
				};
			},
		},
		testid:String,
		isValidationOn:Boolean,
		disabled:Boolean,
		toolTipText:String
	},
	data() {
		return {
			provinces,
		};
	},
	validations:{
		value:{
			street:{
				required
			},
			province:{
				required
			}
		}
	},
	computed:{
		...mapState(['currentRoleLevel']),
		isUserSeparatingParty(){
			return this.currentRoleLevel.roleAssociated === 'party'
		},
	},
	methods:{
		validateState(name) {
			const { $dirty, $error } = this.$v.value[name];
			return $dirty ? !$error : null;
		},
	}
};
</script>

<template>
	<div class="app-input-group address-input">
		<b-row>
			<b-col class="label" :class="{'field-required': isValidationOn}"> Address </b-col>
		</b-row>
		<b-row no-gutters>
			<b-col cols="8" class="input col-11 col-sm-8">
				<b-form-input
					v-model="$v.value.street.$model"
					placeholder="ie: 123 Legal Street"
					:data-testid="testid +'-street-name'"
					:disabled="disabled"
					:state="validateState('street')"
				/>
				<div
					v-if="$v.value.street.$error"
					class="invalid-feedback d-block col-12"
				>
					This is a required field.
				</div>
			</b-col>
		</b-row>
		<b-row no-gutters>
			<b-col  class="input col-5 col-sm-4">
				<b-form-input v-model="value.city" placeholder="City" :data-testid="testid+'-city-name'" :disabled="disabled"/>
			</b-col>
			<b-col  class="input col-6 col-sm-4">
				<b-form-select
					v-model="$v.value.province.$model"
					:options="provinces"
					text-field="name"
					value-field="name"
					:data-testid="testid+'-province'"
					:disabled="disabled"
					:state="validateState('province')"
					:class="{ 'custom-disabled': disabled }"
				>
					<template #first>
						<b-form-select-option :value="undefined" disabled :data-testid="testid+'-provinces'">
							Province
						</b-form-select-option>
					</template>
				</b-form-select>
				<div
					v-if="$v.value.province.$error"
					class="invalid-feedback d-block col-12"
				>
					This is a required field.
				</div>
			</b-col>
			<b-icon-info-circle class="mt-2 ml-2"
				v-if="toolTipText && isUserSeparatingParty"
				v-b-tooltip="toolTipText">
			</b-icon-info-circle>
		</b-row>
	</div>
</template>
