var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-input',{attrs:{"fields":_vm.fields,"testid":_vm.testid,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"amount",fn:function(ref){
var value = ref.value;
var input = ref.input;
var index = ref.index;
return [_c('input-group',{staticClass:"input-group",class:[_vm.testid.includes('liability-exclusion') ? 'mb-width' : ''],attrs:{"value":value,"size":"sm","dollar":"","testid":_vm.testid+'amount-'+index,"isExclusion":true,"disabled":_vm.disabled},on:{"input":input}})]}},{key:"ownership",fn:function(ref){
var value = ref.value;
var input = ref.input;
var index = ref.index;
return [_c('b-form-select',{class:[_vm.testid.includes('liability-exclusion') ? 'mb-style' : '', 'select-class', { 'custom-disabled': _vm.disabled }],attrs:{"options":[].concat( _vm.partnerChoices ),"value":value,"data-testid":_vm.testid+'claiming-'+index,"disabled":_vm.disabled},on:{"input":input}})]}},{key:"reason",fn:function(ref){
var reason = ref.value;
var input = ref.input;
var exclusion = ref.row;
var index = ref.index;
return [_c('b-form-select',{staticClass:"p-component select-class",class:{ 'custom-disabled': _vm.disabled },attrs:{"value":reason,"size":"sm","options":[
				{
					text: 'it was owned before the relationship began',
					value: 'before the relationship between the parties began',
				},
				{
					text: 'acquired through inheritance',
					value: 'through an inheritance',
				},
				{ text: 'acquired by gift', value: 'as a gift' },
				{
					text: 'acquired by settlement/award of damages',
					value: ("through a settlement or an award of damages; to a spouse as compensation for injury or loss that does not represent compensation for loss to both parties or lost income of " + (_vm.spouse(
						exclusion.ownership
					))),
				},
				{
					text: 'acquired from insurance proceeds',
					value: ("from money paid or payable under an insurance policy that does not represent compensation for loss to both parties or lost income of " + (_vm.preferredName(
						exclusion.ownership
					))),
				},
				{
					text: 'agreed to exclude',
					value: 'excluded',
				},
				{
					text: 'acquired after separation',
					value: 'acquired after separation',
				},
				{
					text: 'the property is held in trust for another person',
					value:'in trust',
				} ],"data-testid":_vm.testid+'reason-'+index,"disabled":_vm.disabled},on:{"input":input}})]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }